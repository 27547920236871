import facepic from "./pics/facepic.png";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <MainBody>
        <About />
      </MainBody>
    ),
  }
]);

function App() {
  return <RouterProvider router={router} />;
}

function MainBody(props) {
  return (
    <div class="flex h-screen">
      <div class="w-4/12 flex-initial" />
      <div className="pt-32 font-mono ui-monospace">{props.children}</div>
      <div class="w-4/12 flex-initial" />
    </div>
  );
}
function About() {
  return (
    <div class=" flex justify-between">
      <div>
        <h1 class="text-5xl ">
          Henry Quan <br />
        </h1>
        <h1 class="text-5xl pb-8">权恒</h1>
      </div>
      <img src={facepic} width={200} height={100} alt="my face" />
    </div>
  );
}

export default App;
